import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion,  faUserShield } from "@fortawesome/free-solid-svg-icons";
import { default as config } from "../api/config.json";

const Unauthenticated = () => {
    let currentURL = window.location.href;
    let loginUrl = config.LOGIN_URL;
    window.location.href = loginUrl + "?" + new URLSearchParams({returnUrl: currentURL}).toString()
  return (
    <div>
      <Container style={{ marginTop: "2rem" }}>
        <div>
          <div
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
              fontSize: "10rem",
            }}
          >
            401
          </div>
          <div>
            <div style={{ position: "relative", left: "50%" }}>
              <FontAwesomeIcon size={"7x"} icon={faQuestion} />
            </div>
            <div style={{ position: "relative", left: "30%" }}>
              <FontAwesomeIcon size={"9x"} icon={faUserShield} color={"green"} />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <h1 className={"display-4"}>
            Chybné přihlášení uživatele...
            </h1>
            <p className={"lead"}>
              Omlouváme se, ale nepodařilo se nám Vás ověřit a přihlásit do aplikace. Zkuste se přihlásit znovu.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Unauthenticated;
