import "./ElectroProtocol.scss";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {
  IElectroProtocol,
  IProtocolArea,
  IProtocolExpandingValue,
  IProtocolPartRule,
} from "../../../app/models/protocol";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import logo from "../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import ProtocolHeaderCarInfo from "./protocolParts/ProtocolHeaderCarInfo";
import ExecutedTestArea from "./protocolParts/ExecutedTestArea";
import BateryArea from "./protocolParts/BateryArea";
import FlashParamsArea from "./protocolParts/FlashParamsArea";
import DefectsArea from "./protocolParts/DefectsArea";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import PhevArea from "./protocolParts/PhevArea";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import Can from "../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import DefectsCount from "./protocolParts/DefectsCount";
import {defectMutator} from "./protocolParts/DefectMutator";
import AlertMessage from "../../alert/AlertMessage";

interface PartialProtocolParams {
  protocolTypeId: string;
  carId: string;
  carTestId: string;
  carProtocolId: string;
}

const ElectroProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums } = rootStore.enumStore;
  const { user } = rootStore.authStore;
  const {
    loadElectroProtocol,
    saveElectroProtocol,
    electroProtocolRegistry,
    isLoading,
  } = rootStore.protocolStore;
  const [electroProtocol, setElectroProtocol] = useState<
    IElectroProtocol | undefined
  >(undefined);
  const [flashParams, setFlashParams] = useState<IProtocolExpandingValue[]>([]);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  useEffect(() => {
    const protocolTypeId = Number(match.params.protocolTypeId);
    const carId = Number(match.params.carId);
    const carTestId = Number(match.params.carTestId);
    const carProtocolId = Number(match.params.carProtocolId);

    loadEnums();
    loadElectroProtocol(carId, carTestId, protocolTypeId, carProtocolId).then(
      () => {
        const electroProtocol = electroProtocolRegistry.get(
          `C${carId}-CT${carTestId}-PT${protocolTypeId}`
        );
        setElectroProtocol(electroProtocol);
        setFlashParams(
          electroProtocol?.flashParamsArea?.flashParams?.map(
            (p, index: number) => {
              p.rownumber = index;
              return p;
            }
          ) || []
        );
      }
    );
  }, [
    match.params.carId,
    match.params.carTestId,
    match.params.protocolTypeId,
    loadElectroProtocol,
    loadEnums,
    electroProtocolRegistry,
  ]);

  const handleAddFlashParam = () => {
    const filteredArray = flashParams
      .filter((x) => x.rownumber !== undefined)
      .map((x) => (x.rownumber ? x.rownumber : 0));
    const rowNumber: number = Math.max.apply(
      Math,
      filteredArray.map((x) => x)
    );
    const flashParamNew: IProtocolExpandingValue = {
      controlUnit: undefined,
      originalState: undefined,
      newState: undefined,
      codingParams: undefined,
      version: undefined,
      note: undefined,
      rownumber: rowNumber + 1,
      isDeleted: false,
    };
    setFlashParams([...flashParams, flashParamNew]);
  };

  const handleRemoveFlashParam = (rowNumber: number | undefined) => {
    if (rowNumber === undefined) return;
    var flastParam = flashParams.find((p) => p.rownumber == rowNumber);
    if (flastParam) {
      flastParam.isDeleted = true;
      setFlashParams([...flashParams, flastParam]);
    }
  };

  const handleFinalFormSubmit = (values: IElectroProtocol) => {

    electroProtocolRegistry.set(
      `C${values.carId}-CT${values.carTestId}-PT${values.protocolTypeId}`,
      values
    );

    setErrors([]);
    setShowSubmitError(false);

    saveElectroProtocol(values)
    .then((response) => {
      setEdit(false);
      if (!match.params.carProtocolId || match.params.carProtocolId == 'null')
        history.push(
          `/ElectroProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`
        );
      setElectroProtocol(response);
      setFlashParams(
        response?.flashParamsArea?.flashParams?.map((p, index: number) => {
          p.rownumber = index;
          return p;
        }) || []
      );
    })
    .catch((error) => {
      setErrors(Object.values(error.response?.data?.errors));
      setShowSubmitError(true);
    });
    //console.log(values);
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    form.reset();
    const carId = Number(match.params.carId);
    const carTestId = Number(match.params.carTestId);
    const protocolTypeId = Number(match.params.protocolTypeId);
    const electroProtocol = electroProtocolRegistry.get(
      `C${carId}-CT${carTestId}-PT${protocolTypeId}`
    );
    setElectroProtocol(electroProtocol);
    setFlashParams(
      electroProtocol?.flashParamsArea?.flashParams?.map((p, index: number) => {
        p.rownumber = index;
        p.isDeleted = false;
        return p;
      }) || []
    );
    setEdit(false);
  };

  return (
    <div
      id="ElectroProtocol"
      className="container"
      style={{ marginBottom: "3rem" }}
    >
      <div className="printheader">
        <div className="printheader__left">
          <div className="printheader--image">
            <img src={logo} height={80} alt='logo'/>
          </div>
        </div>
        <div className="printheader__right">
          <div className="text--header">Zkouškový protokol</div>
          <div className="text--text">
            Pracovní skupina{" "}
            <span className="text--expert-group">
              {electroProtocol?.carTest.expertGroupName}
            </span>
          </div>
          <div className="text--text">
            <span>{electroProtocol?.placeCode}</span> {electroProtocol?.placeDescription}
          </div>
        </div>
      </div>

      <div className="printfooter">
        <Table>
          <tbody>
            <tr>
              <td colSpan={11}>
                {electroProtocol?.carTest.placeCode}{" "}
                {electroProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                Česká republika
              </td>
            </tr>
            <tr>
              <td colSpan={3}>{electroProtocol?.documentName}</td>
              <td>{electroProtocol?.registrationNumber}</td>
              <td>{electroProtocol?.storageMethod}</td>
              <td>{electroProtocol?.shreddingSign}</td>
              <td colSpan={2}>{electroProtocol?.shreddingTriggerEvent}</td>
              <td>{electroProtocol?.classification}</td>
              <td>{electroProtocol?.storageLocation}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <table className="table-page-content">
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div>
                <FinalForm
                  onSubmit={handleFinalFormSubmit}
                  initialValues={electroProtocol}
                  mutators={{
                    handleChangeExecutedTest: (args, state, utils) => {
                      const fieldValue: boolean = args[0].target.checked;
                      const fieldName = args[0].currentTarget.name;
                      var name: string = "";
                      if (fieldValue === true) {
                        var ruleIndex: number = -1;
                        fieldName
                          .split(".")
                          .map((objName: string, i: number) => {
                            if (objName.includes("partRules")) {
                              ruleIndex = Number.parseInt(
                                objName
                                  .replace("partRules", "")
                                  .replace("[", "")
                                  .replace("]", "") || "-1"
                              );
                            }
                            name = objName;
                          });

                        if (name == "isOK") {
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].isDefect`,
                            () => false
                          );
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].notPerform`,
                            () => false
                          );
                        }
                        if (name == "isDefect") {
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].isOK`,
                            () => false
                          );
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].notPerform`,
                            () => false
                          );
                        }
                        if (name == "notPerform") {
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].isOK`,
                            () => false
                          );
                          utils.changeValue(
                            state,
                            `executedTestArea.partRules[${ruleIndex}].isDefect`,
                            () => false
                          );
                        }
                      }
                    },
                    changeMeasuredValues: (args, state, utils) => {
                      const fieldName = args[0].currentTarget.name;
                      var areaName: string = "";
                      var groupIndex: number = -1;
                      var ruleIndex: number = -1;
                      var measuredValIndex: number = -1;

                      fieldName.split(".").map((objName: string, i: number) => {
                        if (i == 0) {
                          areaName = objName;
                        }
                        if (objName.includes("protocolPartGroups")) {
                          groupIndex = Number.parseInt(
                            objName
                              .replace("protocolPartGroups", "")
                              .replace("[", "")
                              .replace("]", "") || "-1"
                          );
                        }
                        if (objName.includes("partRules")) {
                          ruleIndex = Number.parseInt(
                            objName
                              .replace("partRules", "")
                              .replace("[", "")
                              .replace("]", "") || "-1"
                          );
                        }
                        if (objName.includes("measuredValues")) {
                          measuredValIndex = Number.parseInt(
                            objName
                              .replace("measuredValues", "")
                              .replace("[", "")
                              .replace("]", "") || "-1"
                          );
                        }
                      });

                      var partRule: IProtocolPartRule | undefined;
                      var partRuleFieldName: string = "";
                      if (areaName == "phevArea") {
                        const area: IProtocolArea | undefined =
                          state.lastFormState?.values?.phevArea;
                        if (area) {
                          if (area.protocolPartGroups && groupIndex >= 0) {
                            const group = area.protocolPartGroups[groupIndex];
                            if (group.partRules && ruleIndex >= 0) {
                              partRule = group.partRules[ruleIndex];
                              partRuleFieldName = `phevArea.protocolPartGroups[${groupIndex}].partRules[${ruleIndex}]`;
                            }
                          }
                          if (area.partRules && ruleIndex >= 0) {
                            partRule = area.partRules[ruleIndex];
                            partRuleFieldName = `phevArea.partRules[${ruleIndex}]`;
                          }

                          if (partRule) {
                            var measuredValue: string = "";
                            if (
                              partRule.measuredValues &&
                              partRule.measuredValues?.length === 1
                            ) {
                              measuredValue = partRule.measuredValues[0];
                            } else if (
                              partRule.measuredValues &&
                              partRule.measuredValues?.length > 1
                            ) {
                              measuredValue =
                                partRule.measuredValues.join(" ; ");
                            }
                            const measuredValueField =
                              partRuleFieldName + ".measuredValue";
                            utils.changeValue(
                              state,
                              measuredValueField,
                              () => measuredValue
                            );
                          }
                        }
                      }
                    },
                    calculateDefects: defectMutator.calculateDefects
                  }}
                  render={({ handleSubmit, values, form }) => (
                    <Form onSubmit={handleSubmit} id="protocol-form">
                      <div className="pageheader  no-print">
                        <div className="pageheader__left">
                          <h1>Zkouškový protokol</h1>
                          <h3>
                            Pracovní skupina{" "}
                            <span className="text--expert-group">
                              {electroProtocol?.carTest.expertGroupName}
                            </span>{" "}
                          </h3>
                        </div>
                        <div className="pageheader__right">
                          <Can
                            roleId={user?.userRoleId}
                            perform="electro-protocol:edit"
                            data={{
                              userExpertGroupId: user?.expertGroupId,
                            }}
                            yes={
                              <EditSaveResetButtons
                                edit={edit}
                                setEdit={setEdit}
                                handleReset={handleReset}
                                form={form}
                                isSaving={false}
                                printButton={true}
                              />
                            }
                            no={
                              <Button
                                variant="outline-info"
                                type="button"
                                onClick={() => window.print()}
                              >
                                <FontAwesomeIcon icon={faPrint} title="Tisk" />{" "}
                                Tisk
                              </Button>
                            }
                          />
                          {!edit && (
                            <Fragment>
                              <Button
                                variant="outline-secondary"
                                style={{
                                  marginLeft: "1rem",
                                }}
                                key={"CarDetail_" + electroProtocol?.car.carId}
                                as={Link}
                                to={`/car/${electroProtocol?.car.carId}`}
                              >
                                Detail vozu
                              </Button>
                              <Button
                                variant="outline-secondary"
                                style={{
                                  marginLeft: "1rem",
                                }}
                                key={
                                  "CarTest_" +
                                  electroProtocol?.carTest.carTestId
                                }
                                as={Link}
                                to={`/carTest/${electroProtocol?.carTest.carTestId}`}
                              >
                                Detail zkoušky
                              </Button>
                            </Fragment>
                          )}
                        </div>
                      </div>

                      <AlertMessage
                        type="danger"
                        heading="Nastala chyba při ukládání protokolu"
                        show={showSubmitError}
                        setShow={setShowSubmitError}
                        messageList={errors}
                    />

                      <div className="pagecontent">
                        {!isLoading && electroProtocol && (
                          <ProtocolHeaderCarInfo
                            car={electroProtocol.car}
                            carTest={electroProtocol.carTest}
                            measurementDate={undefined}
                          />
                        )}

                        <div className="protocol">
                          {/* Tabulka s oblasti provadenych zkousek */}
                          {!isLoading && electroProtocol?.executedTestArea && (
                            <ExecutedTestArea
                              testArea={electroProtocol?.executedTestArea}
                              isEdit={edit}
                              isDisabled={
                                values.executedTestArea?.notPerform || false
                              }
                              form={form}
                            />
                          )}
                          {/* Tabulka s oblasti baterie */}
                          {!isLoading && electroProtocol?.bateryArea && (
                            <BateryArea
                              bateryArea={electroProtocol?.bateryArea}
                              isEdit={edit}
                              isDisabled={
                                values.bateryArea?.notPerform || false
                              }
                            />
                          )}
                          {/*Poznamka k diagnostice*/}
                          {!isLoading && (
                            <Fragment>
                              <Table className="protocol-table">
                                <thead>
                                  <tr>
                                    <th className="headingrow" colSpan={10}>
                                      <h2>
                                        {
                                          electroProtocol?.diagnosticNoteArea
                                            ?.name
                                        }
                                      </h2>
                                    </th>
                                    <th
                                      className={`headingrow ${
                                        values.diagnosticNoteArea?.notPerform
                                          ? ""
                                          : "no-print"
                                      }`}
                                      colSpan={2}
                                    >
                                      <Field
                                        name={`diagnosticNoteArea.notPerform`}
                                        title="Neprovedeno"
                                        options={[
                                          { key: 1, value: "ano" },
                                          { key: 0, value: " ne " },
                                        ]}
                                        format={(v) => (v ? 1 : 0)}
                                        parse={(v) => (v ? true : false)}
                                        type={"checkbox"}
                                        initialValue={
                                          electroProtocol?.diagnosticNoteArea
                                            ?.notPerform || false
                                        }
                                        component={CheckboxInput}
                                        disabled={!edit}
                                        labelWidth={8}
                                        valueWidth={4}
                                      />
                                    </th>
                                  </tr>
                                </thead>
                              </Table>
                              <Table
                                className={`protocol-table ${
                                  values.diagnosticNoteArea?.notPerform
                                    ? "no-print"
                                    : ""
                                }`}
                              >
                                <tbody>
                                  <tr
                                    className={`${
                                      values.diagnosticNoteArea?.notPerform
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <td colSpan={12}>
                                      <Field<string>
                                        name="diagnosticNoteArea.note"
                                        initialValue={
                                          electroProtocol?.diagnosticNoteArea
                                            ?.note
                                        }
                                        component={TextAreaInput}
                                        disabled={
                                          !edit ||
                                          values.diagnosticNoteArea?.notPerform
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Fragment>
                          )}
                          {/*Tabulka Flash/parametrizace/kódování*/}
                          {!isLoading &&
                            electroProtocol &&
                            electroProtocol?.flashParamsArea && (
                              <FlashParamsArea
                                isEdit={edit}
                                flsahParams={flashParams}
                                flsahParamsArea={
                                  electroProtocol?.flashParamsArea
                                }
                                isDisabled={
                                  values.flashParamsArea?.notPerform || false
                                }
                                handleAddFlashParam={handleAddFlashParam}
                                handleRemoveFlashParam={handleRemoveFlashParam}
                              />
                            )}
                          {/*Tabulka PHEV*/}
                          {!isLoading && electroProtocol?.phevArea && (
                            <PhevArea
                              phevArea={electroProtocol?.phevArea}
                              isEdit={edit}
                              form={form}
                              values={values}
                            />
                          )}
                          {/*Tabulka zavad*/}
                          {!isLoading &&
                            electroProtocol &&
                            electroProtocol?.defectsArea && (
                              <DefectsArea
                                isEdit={edit}
                                isDisabled={
                                  values.defectsArea?.notPerform || false
                                }
                              />
                            )}

                          {/*Poznamka k celkovemu protokolu*/}
                          {!isLoading && (
                            <div style={{ paddingTop: "1em" }}>
                              <h2>Poznámka</h2>
                              <Field<string>
                                name="note"
                                initialValue={electroProtocol?.note}
                                component={TextAreaInput}
                                disabled={!edit}
                                rows={5}
                              />
                            </div>
                          )}

                          {!isLoading && electroProtocol && (
                            <DefectsCount />
                          )}
                          {!isLoading && electroProtocol && (
                            <Table style={{ marginTop: "50px" }}>
                              <tbody>
                                <tr>
                                  <td style={{ border: "0" }}>
                                    <Form.Group controlId={"defectsCount"}>
                                      <Row>
                                        <Col xs={3}>
                                          <Form.Label>
                                            Odpovědná osoba:
                                          </Form.Label>
                                        </Col>
                                        <Col
                                          xs={5}
                                          style={{
                                            textAlign: "center",
                                            borderBottom: "1px solid lightgray",
                                          }}
                                        >
                                          <Form.Label>
                                            {
                                              electroProtocol.workersExecutingTest
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default observer(ElectroProtocol);
