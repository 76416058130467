import "./WaterResistanceProtocol.scss";
import React, {Fragment, useContext, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IWaterResistanceProtocol,} from "../../../../app/models/protocol";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import EditSaveResetButtons from "../../../ButtonGroups/EditSaveResetButtons";
import logo from "../../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";

import Can from "../../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import DefectsArea from "../protocolParts/DefectsArea";
import ProtocolHeaderCarInfo from "../../partialProtocols/protocolParts/ProtocolHeaderCarInfo";
import {FormApi} from "final-form";
import WashArea from "./WashArea";
import {useWaterResistanceProtocolMutation, useWaterResistanceProtocolQuery} from "../../../../app/api/client";
import {toast} from "react-toastify";
import Loader from "../../../loader/Loader";
import DefectsCount from "../protocolParts/DefectsCount";
import {defectMutator} from "../protocolParts/DefectMutator";
import AlertMessage from "../../../alert/AlertMessage";

interface PartialProtocolParams {
  protocolTypeId: string;
  carId: string;
  carTestId: string;
  carProtocolId: string;
}

const WaterResistanceProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.authStore;

  const [edit, setEdit] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const protocolTypeId = Number(match.params.protocolTypeId);
  const carId = Number(match.params.carId);
  const carTestId = Number(match.params.carTestId);
  const carProtocolId = Number(match.params.carProtocolId);
  const {
    data: waterResistanceProtocol,
    isFetching: isLoading
  } = useWaterResistanceProtocolQuery(carId, carTestId, protocolTypeId, carProtocolId);
  const {mutateAsync} = useWaterResistanceProtocolMutation();

  const handleFinalFormSubmit = async (protocol: IWaterResistanceProtocol) => {

      setErrors([]);
      setShowSubmitError(false);

      mutateAsync(protocol)
      .then((response) => {
        toast.success(`Protokol byl uložen`);
        setEdit(false);
        if (!match.params.carProtocolId || match.params.carProtocolId == 'null') {
          history.push(
              `/WaterResistanceProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`
          );
        }
      })
      .catch((error) => {
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
        toast.error(`Vyskytla se chyba při uložení protokolu`);
      });
  };

  // Reset all form values to initial values
  const handleReset = (form: FormApi) => {
    form.reset();
    setEdit(false);
  };

  if (isLoading) {
    return <Loader/>
  }

  return (
    <div
      id="WaterResistanceProtocol"
      className="container"
      style={{ marginBottom: "3rem" }}
    >
      <div className="printheader">
        <div className="printheader__left">
          <div className="printheader--image">
            <img src={logo} alt='logo' />
          </div>
        </div>
        <div className="printheader__right">
          <div className="text--header">Zkouškový protokol</div>
          <div className="text--text">
            Pracovní skupina{" "}
            <span className="text--expert-group">
              {waterResistanceProtocol?.carTest.expertGroupName}
            </span>
          </div>
          <div className="text--text">
            <span>{waterResistanceProtocol?.placeCode}</span> {waterResistanceProtocol?.placeDescription}
          </div>
        </div>
      </div>

      <div className="printfooter">
        <Table>
          <tbody>
            <tr>
              <td colSpan={11}>
                {waterResistanceProtocol?.carTest.placeCode}{" "}
                {waterResistanceProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                Česká republika
              </td>
            </tr>
            <tr>
              <td colSpan={3}>{waterResistanceProtocol?.documentName}</td>
              <td>{waterResistanceProtocol?.registrationNumber}</td>
              <td>{waterResistanceProtocol?.storageMethod}</td>
              <td>{waterResistanceProtocol?.shreddingSign}</td>
              <td colSpan={2}>{waterResistanceProtocol?.shreddingTriggerEvent}</td>
              <td>{waterResistanceProtocol?.classification}</td>
              <td>{waterResistanceProtocol?.storageLocation}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <table className="table-page-content">
        <thead>
          <tr>
            <td>
              {/* <!--placeholder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div>
                <FinalForm
                  onSubmit={handleFinalFormSubmit}
                  initialValues={waterResistanceProtocol}
                  mutators={defectMutator}
                  render={({ handleSubmit, values, form }) => (
                    <Form onSubmit={handleSubmit} id="protocol-form">
                      <div className="pageheader  no-print">
                        <div className="pageheader__left">
                          <h1>Zkouškový protokol</h1>
                          <h3>
                            Pracovní skupina{" "}
                            <span className="text--expert-group">
                              {waterResistanceProtocol?.carTest.expertGroupName}
                            </span>{" "}
                          </h3>
                        </div>
                        <div className="pageheader__right">
                          <Can
                            roleId={user?.userRoleId}
                            perform="water-resistance-protocol:edit"
                            data={{
                              userExpertGroupId: user?.expertGroupId,
                            }}
                            yes={
                              <EditSaveResetButtons
                                edit={edit}
                                setEdit={setEdit}
                                handleReset={handleReset}
                                form={form}
                                isSaving={false}
                                printButton={true}
                              />
                            }
                            no={
                              <Button
                                variant="outline-info"
                                type="button"
                                onClick={() => window.print()}
                              >
                                <FontAwesomeIcon icon={faPrint} title="Tisk" />{" "}
                                Tisk
                              </Button>
                            }
                          />
                          {!edit && (
                            <Fragment>
                              <Button
                                variant="outline-secondary"
                                style={{
                                  marginLeft: "1rem",
                                }}
                                key={"CarDetail_" + waterResistanceProtocol?.car.carId}
                                as={Link}
                                to={`/car/${waterResistanceProtocol?.car.carId}`}
                              >
                                Detail vozu
                              </Button>
                              <Button
                                variant="outline-secondary"
                                style={{
                                  marginLeft: "1rem",
                                }}
                                key={
                                  "CarTest_" +
                                  waterResistanceProtocol?.carTest.carTestId
                                }
                                as={Link}
                                to={`/carTest/${waterResistanceProtocol?.carTest.carTestId}`}
                              >
                                Detail zkoušky
                              </Button>
                            </Fragment>
                          )}
                        </div>
                      </div>

                      <AlertMessage
                        type="danger"
                        heading="Nastala chyba při ukládání protokolu"
                        show={showSubmitError}
                        setShow={setShowSubmitError}
                        messageList={errors}
                      />

                      <div className="pagecontent">
                        {!isLoading && waterResistanceProtocol && (
                          <ProtocolHeaderCarInfo
                            car={waterResistanceProtocol.car}
                            carTest={waterResistanceProtocol.carTest}
                            measurementDate={undefined}
                          />
                        )}

                        {!isLoading && waterResistanceProtocol &&
                            (
                                <WashArea isEdit={edit} />
                            )}

                        <div className="protocol">
                          {/*Tabulka zavad*/}
                          {!isLoading &&
                            waterResistanceProtocol &&
                            waterResistanceProtocol?.defectsArea && (
                              <DefectsArea
                                isEdit={edit}
                                isDisabled={
                                  values.defectsArea?.notPerform || false
                                }
                              />
                            )}

                          {/*Poznamka k celkovemu protokolu*/}
                          {!isLoading && (
                            <div style={{ paddingTop: "1em" }}>
                              <h2>Poznámka</h2>
                              <Field<string>
                                name="note"
                                component={TextAreaInput}
                                disabled={!edit}
                                rows={5}
                              />
                            </div>
                          )}

                          {!isLoading && waterResistanceProtocol && (
                            <DefectsCount />
                          )}
                          {!isLoading && waterResistanceProtocol && (
                            <Table style={{ marginTop: "50px" }}>
                              <tbody>
                                <tr>
                                  <td style={{ border: "0" }}>
                                    <Form.Group controlId={"defectsCount"}>
                                      <Row>
                                        <Col xs={3}>
                                          <Form.Label>
                                            Odpovědná osoba:
                                          </Form.Label>
                                        </Col>
                                        <Col
                                          xs={5}
                                          style={{
                                            textAlign: "center",
                                            borderBottom: "1px solid lightgray",
                                          }}
                                        >
                                          <Form.Label>
                                            {
                                              waterResistanceProtocol.workersExecutingTest
                                            }
                                          </Form.Label>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default observer(WaterResistanceProtocol);
