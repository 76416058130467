import React, { useContext, useState } from "react";
import { Card, Button, Table, Col, Row, ButtonGroup } from "react-bootstrap";
import { ICarModelForm, IEnums, ITestTypeForm } from "../../../app/models/enum";
import EditableEnumTableRow from "../forms/EditableEnumTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditableTestTypeItem from "../forms/EditableTestTypeItem";
import { RootStoreContext } from "../../../app/stores/rootStore";
import NewEnumItemForm from "../forms/NewEnumItemForm";
import { observer } from "mobx-react-lite";
import FilterActiveButtonGroup from "../../ButtonGroups/FilterActiveButtonGroup";
import { sortByStatusAndCode } from "../../../app/common/utils/sort";

interface IProps {
  enums: IEnums | undefined;
}

const CarModelsAndTestTypesTab: React.FC<IProps> = ({ enums }) => {
  const rootStore = useContext(RootStoreContext);
  const { updateCarModel, updateTestType } = rootStore.enumStore;
  const { openModal } = rootStore.modalStore;

  const [editedItemId, setEditedItemId] = useState<string | undefined>(
    undefined
  );

  const [allTestTypesEnabled, setAllTestTypesEnabled] =
    useState<boolean>(false);
  const [allCarModelsEnabled, setAllCarModelsEnabled] =
    useState<boolean>(false);

  const handleEditCarTestTypeItem = (form: ITestTypeForm) => {
    updateTestType(form).then(() => {
      setEditedItemId(undefined);
    });
  };

  const handleEditCarModelItem = (form: ICarModelForm) => {
    updateCarModel(form).then(() => {
      setEditedItemId(undefined);
    });
  };

  const handleAddCarModelItem = async (form: ICarModelForm) => {
    return updateCarModel(form);
  };

  const handleAddTestTypeItem = async (form: ITestTypeForm) => {
    return updateTestType(form);
  };

  const handleAddCarModelClick = () => {
    openModal(
      <NewEnumItemForm
        handleCreateItem={handleAddCarModelItem}
        emissionFreeParam={true}
        modelVdsCodeParam={true}
      />,
      {
        preventClosing: true,
        title: "Přidání modelu vozu",
      }
    );
    return;
  };

  const handleAddTestTypeClick = () => {
    openModal(<NewEnumItemForm handleCreateItem={handleAddTestTypeItem} />, {
      preventClosing: true,
      title: "Přidání typu zkoušek",
    });
    return;
  };

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Modely vozů</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allCarModelsEnabled}
                  setAllEnabled={setAllCarModelsEnabled}
                />
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddCarModelClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Název</th>
                    <th>VDS kód</th>
                    <th>Bezemisní</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.carModels
                    .slice()
                    .filter((item) => allCarModelsEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableEnumTableRow
                        editedItemId={editedItemId}
                        setEditedItemId={setEditedItemId}
                        handleEditItem={handleEditCarModelItem}
                        id={item.id}
                        name={item.name}
                        code={item.code}
                        isActive={item.isActive}
                        enumName="carModels"
                        key={item.id}
                        emissionFree={item.emissionFree}
                        emissionColumn={true}
                        vdsCodeColumn={true}
                        vdsCode={item.modelVdsCode}
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Typy zkoušek</div>
              <div>
                <FilterActiveButtonGroup
                  allEnabled={allTestTypesEnabled}
                  setAllEnabled={setAllTestTypesEnabled}
                />
                <Button
                  variant="primary"
                  className="footer__btn-add"
                  onClick={handleAddTestTypeClick}
                  title="Přidat další"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Název</th>
                    <th>Aktivní</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {enums?.testTypes
                    .slice()
                    .filter((item) => allTestTypesEnabled || item.isActive)
                    .sort(sortByStatusAndCode())
                    .map((item) => (
                      <EditableTestTypeItem
                        key={item.key}
                        editedItemId={editedItemId}
                        setEditedItemId={setEditedItemId}
                        handleEditItem={handleEditCarTestTypeItem}
                        id={item.key}
                        name={item.value}
                        code={item.code}
                        isActive={item.isActive}
                        enumName="testTypes"
                      />
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default observer(CarModelsAndTestTypesTab);
