import {MutationCache, QueryClient, useMutation, useQuery} from "@tanstack/react-query";
import agent from "./agent";
import {
    IAcousticsProtocol,
    IConformityProtocol,
    IDrivingCharacteristicsProtocol,
    IPartSettingDTO,
    IRidingNoisesProtocol,
    IRuleSettingDTO,
    IWaterResistanceProtocol,
} from "../models/protocol";
/*
import {toast} from "react-toastify";
import {AxiosError} from "axios";

const mutationCache = new MutationCache({
    onError: (error: unknown) => {
        const errors = (error as AxiosError).response?.data?.errors;
        toast.error(errors?.join("\n") || 'Vyskytla se chyba');
    },
})
*/

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
    //mutationCache
})


export const useWaterResistanceProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['WaterResistanceProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.waterResistanceProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useWaterResistanceProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IWaterResistanceProtocol) => {
            return agent.Protocols.updateWaterResistanceProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['WaterResistanceProtocol']),
    });
}

export const useConformityProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['ConformityProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.conformityProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useConformityProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IConformityProtocol) => {
            return agent.Protocols.updateConformityProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['ConformityProtocol'])
    });
}

export const useRidingNoisesProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['RidingNoisesProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.ridingNoisesProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useRidingNoisesProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IRidingNoisesProtocol) => {
            return agent.Protocols.updateRidingNoisesProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['RidingNoisesProtocol'])
    });
}

export const useAcousticsProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['AcousticsProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.acousticsProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useAcousticsProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IAcousticsProtocol) => {
            return agent.Protocols.updateAcousticsProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['AcousticsProtocol'])
    });
}

export const useLoadEnumsQuery = () => {
    return useQuery({
        queryKey: ['Enums'],
        queryFn: () => {
            return agent.Enums.list();
        },
        refetchOnMount: false,
    });
}

export const useProtocolCodeTableValuesQuery = (protocolTypeId: number, carTestId: number, protocolCodeTableId?: number) => {
    return useQuery({
        queryKey: ['ProtocolCodeTableValues', {protocolTypeId, carTestId, protocolCodeTableId}],
        queryFn: () => {
            return agent.Protocols.getProtocolCodeTableValues(protocolTypeId, carTestId, protocolCodeTableId);
        },
        refetchOnMount: false,
      //  enabled: !!protocolCodeTableId
    });
}

export const useDrivingCharacteristicsProtocolQuery = (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number) => {
    return useQuery({
        queryKey: ['EngineDrivingCharacteristicsProtocol', {carId, carTestId, protocolTypeId, carProtocolId}],
        queryFn: () => {
            return agent.Protocols.drivingCharacteristicsProtocol(carId, carTestId, protocolTypeId, carProtocolId);
        },
    });
}
export function useDrivingCharacteristicsProtocolMutation() {
    return useMutation({
        mutationFn: (protocol: IDrivingCharacteristicsProtocol) => {
            return agent.Protocols.updateDrivingCharacteristicsProtocol(protocol);
        },
        onSuccess: () => queryClient.invalidateQueries(['EngineDrivingCharacteristicsProtocol'])
    });
}

export const createGroupScheduleOptions = (expertGroupId: number, start: Date, end: Date, userId?: number) => {
    return {
        queryKey: [
            'GroupSchedule',
            {start, end, expertGroupId, userId}
        ],
        queryFn: () => {
            return agent.Scheduling.loadDateRangeByExpertGroupAndUser(expertGroupId, start, end,  userId);
        },
        staleTime: 30 * 1000
    }
}

export const useProtocolRuleSettingsQuery = (protocolTypeId: number, carModelId?: number, carBodyTypeId?: number) => {
    return useQuery({
        queryKey: ['ProtocolRuleSettings', {protocolTypeId, carModelId, carBodyTypeId}],
        queryFn: () => {
            return agent.Protocols.getProtocolRules(protocolTypeId, carModelId, carBodyTypeId);
        },
        refetchOnMount: false,
        enabled: !!protocolTypeId
    });
}
export function useProtocolRuleSettingsMutation() {
    return useMutation({
        mutationFn: (ruleSetting: IRuleSettingDTO) => {
            return agent.Protocols.updateProtocolRule(ruleSetting);
        },
        onSuccess: () => queryClient.invalidateQueries(['ProtocolRuleSettings'])
    });
}

export const useProtocolPartSettingsQuery = (protocolTypeId: number) => {
    return useQuery({
        queryKey: ['ProtocolPartSettings', {protocolTypeId}],
        queryFn: () => {
            return agent.Protocols.getProtocolParts(protocolTypeId);
        },
        refetchOnMount: false,
        enabled: !!protocolTypeId
    });
}

export function useProtocolPartSettingsMutation() {
    return useMutation({
        mutationFn: (partSetting: IPartSettingDTO) => {
            return agent.Protocols.updateProtocolPart(partSetting);
        },
        onSuccess: () => queryClient.invalidateQueries(['ProtocolPartSettings'])
    });
}
