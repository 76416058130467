import axios, {AxiosResponse, CancelToken, CancelTokenSource} from "axios";
import {ICarColorForm, ICarModelForm, ICarModelItemForm, IEnums, ITestTypeForm,} from "../models/enum";
import {IExpertGroupDetail, IExpertGroupForm, IExpertGroupsDTO, IExpertGroupWorkersDTO,} from "../models/expertGroup";
import {
  IBlockingEvent,
  IBlockingEventCreateForm,
  IBlockingEventDeleteForm,
  IBlockingEventUpdateForm,
  IHolidaysAndCorporateVacation,
} from "../models/blockingEvent";
import {IPlanCarsForm} from "../models/plan";
import {IScheduleByExpertGroup, IScheduleRange} from "../models/schedule";
import {
  ICar,
  ICarByVinOrKnrDTO,
  ICarDTO,
  ICarForm,
  ICarList,
  ICarPagedList,
  ICarUpdateResponse,
  IEmissionCarForm,
} from "../models/car";
import {ITestDefinition, ITestDefinitionForm} from "../models/testDefinition";
import {ICarTestDetail, ICarTestFormDTO} from "../models/carTest";
import {toast} from "react-toastify";
import {IUser, IUserForm, IUserListDTO} from "../models/user";
import {
  ICopsCarCondition,
  ICopsCarConditionDTO,
  ICopsCarConditionsDTO,
  ICopsProjectTypeForm,
  ICopsProjectTypeList,
  ICopsSeriesTypeForm,
  ICopsSeriesTypeList,
  ICopsUsageForm,
  ICopsUsageList
} from "../models/copsIntegration";
import {ISettingsForm, ISettingsList} from "../models/settings";
import {IAuthenticateResponse, IAuthPlacesUsersResponse,} from "../models/auth";
import {
  IAcousticsProtocol,
  ICarBodyProtocol,
  IConformityProtocol,
  IDrivingCharacteristicsProtocol,
  IEecProtocol,
  IElectroProtocol,
  IMainProtocol,
  IPartSettingDTO,
  IProtocolAreasSetting,
  IProtocolCodeTableValues,
  IProtocolCopyRulesDTO,
  IProtocolEnums,
  IProtocolPartGroupsSetting,
  IProtocolPartsSetting,
  IProtocolRulesSetting,
  IProtocolTypeInfo,
  IRidingNoisesProtocol,
  IRuleSettingDTO,
  IWaterResistanceProtocol,
} from "../models/protocol"
import {default as config} from "./config.json";
import {IUserDefinedCarFilter} from "../models/userDefinedFilter";
import {ICarAnalysis, ICarAnalysisForm} from "../models/carAnalysis";
import {IPlanningConditionsDTO} from "../models/planningCondition";
import {history} from "../../history";

//axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = "https://api.dev.qspace.ga/api";
//axios.defaults.baseURL = "https://localhost:44327/api";
axios.defaults.baseURL =
  config.REACT_APP_API_URL || "http://localhost:5000/api";

axios.interceptors.request.use(
  (config) => {
    //  console.log(axios.defaults.baseURL);
    const token = window.localStorage.getItem("jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
   (error) => {
     return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }
  if (error.message === "Network Error" && !error.response) {
    history.push("/InternalServerError");
    toast.error(
      "Síťová chyba - zkontrolujte, že běží API a že je nastavená správná URL adresa!"
    );
    return Promise.reject(error);
  }
  const { status } = error.response;
  if (status === 404) {
    history.push("/notfound");
  }
  if (status === 500) {
    history.push("/InternalServerError");
  }
  if (status === 403) {
    history.push("/Unauthorized");
  }
  if (status === 401) {
    //let currentURL = window.location.href;
    //let loginUrl = config.LOGIN_URL;
    //window.location.href = loginUrl + "?" + new URLSearchParams({returnUrl: currentURL}).toString()
    //console.log(loginUrl + "?" + new URLSearchParams({returnUrl: currentURL}).toString());
    history.push("/Unauthenticated");
  }
  return Promise.reject(error);
});

const responseBody = (response: AxiosResponse) => response.data;

const source = new Map<string, CancelTokenSource>();

// pro testovaci ucely, simulace delsiho zpracovani pozadavku
const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );
const requests = {
  get: (url: string, cancelToken?: CancelToken) =>
    axios
      .get(url, {
        cancelToken: cancelToken,
      })
      //   .then(sleep(2000))
      .then(responseBody),

  post: (url: string, body: {}) =>
    axios
      .post(url, body)
      // .then(sleep(5000))
      .then(responseBody),
  put: (url: string, body: {}) =>
    axios
      .put(url, body)
      // .then(sleep(1000))
      .then(responseBody),
  patch: (url: string, body: {}) =>
    axios
      .patch(url, body)
      // .then(sleep(1000))
      .then(responseBody),
  delete: (url: string) =>
    axios
      .delete(url)
      // .then(sleep(1000))
      .then(responseBody),
};

const BlockingEvents = {
  create: (
    blockingEventForm: IBlockingEventCreateForm
  ): Promise<IBlockingEvent[]> =>
    requests.post(`/blockingEvents`, blockingEventForm),
  update: (
    blockingEvent: IBlockingEventUpdateForm
  ): Promise<IBlockingEvent[]> =>
    requests.put(`/blockingEvents`, blockingEvent),
  delete: (
    blockingEvent: IBlockingEventDeleteForm
  ): Promise<IBlockingEvent[]> =>
    requests.put(`/blockingEvents/delete`, blockingEvent),
  detail: (blockingEventId: number): Promise<IBlockingEvent> =>
    requests.get(
      `/blockingEvents/GetBlockingEventDetailById/${blockingEventId}`
    ),
  list: (expertGroupId: number): Promise<IBlockingEvent[]> =>
    requests.get(
      `/blockingEvents/GetBlockingEventsByExpertGroupId/${expertGroupId}}`
    ),
  listVacations: (year: number): Promise<IHolidaysAndCorporateVacation> =>
    requests.get(`/blockingEvents/GetHolidaysAndCorporateVacation/${year}`),
};

const CarTests = {
  details: (id: number): Promise<ICarTestDetail> =>
    requests.get(`/carTests/${id}`),
  update: (carTestDto: ICarTestFormDTO): Promise<ICarTestDetail> =>
    requests.put(`/carTests/${carTestDto.carTest.carTestId}`, carTestDto),
};

const Cars = {
  list: (params: URLSearchParams): Promise<ICarPagedList> =>
    axios.get("/cars", { params: params }).then(responseBody),
  listEmissionCars: (params: URLSearchParams): Promise<ICarPagedList> =>
    axios.get("/cars/emissionCars", { params: params }).then(responseBody),
  details: (id: number): Promise<ICarDTO> => requests.get(`/cars/${id}`),
  listByVinOrKnr: (vin?: string, knr?: string): Promise<ICarByVinOrKnrDTO> =>
    requests.get(`/cars/GetByVINOrKnr?vin=${vin}&knr=${knr}`),
  update: (car: ICarForm): Promise<ICarUpdateResponse> =>
    requests.post("/cars", car),
  updateEmissionCar: (
    emissionCars: IEmissionCarForm[]
  ): Promise<ICarUpdateResponse> =>
    requests.post("/cars/updateEmissionCar", {
      emissionTestCars: emissionCars,
    }),
  updateNote: (id: number, note: string) =>
    requests.patch("/cars/updateNote", { id: id, note: note }),
  takeCarsInTestroom: (carIds: number[]): Promise<ICarList> =>
    requests.post(`cars/takeInTestroom`, { carIds }),
  takeCarsOutOfTestroom: (carIds: number[]): Promise<ICarList> =>
    requests.post(`cars/takeOutOfTestroom`, { carIds }),
  sendToEmission: (carIds: number[]): Promise<ICarList> =>
    requests.post(`cars/sendToEmissionTest`, { carIds }),
  loadFromEmission: (carIds: number[]): Promise<ICarList> =>
    requests.post(`cars/takeFromEmissionTest`, { carIds }),
  transport: (carIds: number[]): Promise<ICarList> =>
    requests.post(`cars/transport`, { carIds }),
  cancelCarTransport: (carId: number, reason:string): Promise<ICarList> =>
    requests.post(`cars/cancelCarTransport`, { carId, reason }),
  search: (params: URLSearchParams): Promise<ICarPagedList> =>
    axios.get("/cars/search", { params: params }).then(responseBody),
  getCarFromCops: (vin?: string, knr?: string): Promise<ICar> =>
    requests.get(`/cars/GetCarFromCops?vin=${vin}&knr=${knr}`),
};

const CopsIntegration = {
  usages: (): Promise<ICopsUsageList> => requests.get("/copsIntegration/usage"),
  projectTypes: (): Promise<ICopsProjectTypeList> =>
    requests.get("/copsIntegration/projectType"),
  seriesTypes: (): Promise<ICopsSeriesTypeList> =>
    requests.get("/copsIntegration/seriesType"),
  updateUsage: (form: ICopsUsageForm): Promise<number> =>
    requests.patch("/copsIntegration/usage", form),
  updateProjectTypes: (form: ICopsProjectTypeForm): Promise<number> =>
    requests.patch("/copsIntegration/projectType", form),
  updateSeriesTypes: (form: ICopsSeriesTypeForm): Promise<number> =>
    requests.patch("/copsIntegration/seriesType", form),
  carConditions: (): Promise<ICopsCarConditionDTO> => requests.get("copsIntegration/carCondition"),
  createsOrUpdates: (copsCarConditions: ICopsCarConditionsDTO): Promise<ICopsCarCondition[]> =>
    requests.post("/copsIntegration/saveCarConditions", copsCarConditions)
};

const Enums = {
  list: (): Promise<IEnums> => requests.get("/enums"),
  updateCarModel: (carModel: ICarModelForm): Promise<number> =>
    requests.post("/enums/carModel", carModel),
  updateTestType: (testType: ITestTypeForm): Promise<number> =>
    requests.post("/enums/testType", testType),
  updateCarEquipment: (carEquipment: ICarModelItemForm): Promise<number> =>
    requests.post("/enums/carEquipment", carEquipment),
  updateCarEngine: (carEngine: ICarModelItemForm): Promise<number> =>
    requests.post("/enums/carEngine", carEngine),
  updateCarBodyType: (carBodyType: ICarModelItemForm): Promise<number> =>
    requests.post("/enums/carBodyType", carBodyType),
  updateCarColor: (carColor: ICarColorForm): Promise<number> =>
    requests.post("/enums/carColor", carColor),
};

const ExpertGroups = {
  list: (): Promise<IExpertGroupsDTO> => requests.get("/expertGroups"),
  details: (id: number): Promise<IExpertGroupDetail> =>
    requests.get(`/expertGroups/${id}`),
  update: (expertGroup: IExpertGroupForm): Promise<IExpertGroupDetail> =>
    requests.post("/expertGroups", expertGroup),
  getWorkers: (
    expertGroupId: number,
    dateFrom: Date,
    dateTo: Date,
    carTestId: number
  ): Promise<IExpertGroupWorkersDTO> =>
    requests.get(
      `/expertGroups/${expertGroupId}/workers?dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}&carTestId=${carTestId}`
    ),
};

const Users = {
  list: (expertGroupId?: number): Promise<IUserListDTO> =>
    requests.get(
      `/users${expertGroupId ? `?expertGroupId=${expertGroupId}` : ""}`
    ),
  notAssigned: (expertGroupId: number): Promise<IUserListDTO> =>
    requests.get(`/users/getNotAssignedUsers/?expertGroupId=${expertGroupId}`),
  details: (id: number): Promise<IUser> => requests.get(`/users/${id}`),
  update: (user: IUserForm): Promise<IUser> => requests.post(`/users`, user),
  listByLogin: (login: string): Promise<IUserListDTO> =>
    requests.get(`users/getByKeyword/${login}`),
};

const UserDefinedFilters = {
  list: (): Promise<IUserDefinedCarFilter[]> =>
    requests.get("/UserDefinedFilters"),
  update: (filter: IUserDefinedCarFilter): Promise<number> =>
    requests.post("/UserDefinedFilters", filter),
  delete: (id: number) => requests.delete(`/UserDefinedFilters/${id}`),
};

const Scheduling = {
  start: (planCars: IPlanCarsForm): Promise<number> =>
    requests.post("/scheduling/start", planCars),
  loadDateRange: (startDate: Date, endDate: Date): Promise<IScheduleRange> => {
    const previousRequest = source.get("loadDateRange");
    if (previousRequest) {
      previousRequest.cancel("Cancel previous request for schedule");
    }
    const newRequest = axios.CancelToken.source();
    source.set("loadDateRange", newRequest);
    return requests.get(
      `/scheduling?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      newRequest!.token
    );
  },
  loadDateRangeByExpertGroupAndUser: (
    expertGroupId: number,
    startDate: Date,
    endDate: Date,
    userId?: number
  ): Promise<IScheduleByExpertGroup> => {
    let urlQuery = `/scheduling/ExpertGroup?expertGroupId=${expertGroupId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    if (userId) urlQuery = urlQuery.concat(`&userId=${userId}`);
    return requests.get(urlQuery);
  },
};

const TestDefinitions = {
  list: (): Promise<ITestDefinition[]> => requests.get("/testDefinitions"),
  update: (form: ITestDefinitionForm): Promise<number | undefined> =>
    requests.post("/testDefinitions", form),
};

const Settings = {
  list: (): Promise<ISettingsList> => requests.get("/settings"),
  update: (form: ISettingsForm) => requests.post("/settings", form),
};

const Auths = {
  login: (userId: number): Promise<IAuthenticateResponse> =>
    requests.get(`/Authenticate/${userId}`),
  listPlacesAndUsers: (): Promise<IAuthPlacesUsersResponse> =>
    requests.get(`/Authenticate/GetPlacesAndUsers`),
  // login: (form: ISettingsForm) => requests.post("/login", form),
};

const Protocols = {
  carBodyProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<ICarBodyProtocol> => {
   let urlQuery = `/protocols/GetCarBodyProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
   if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
   return requests.get(urlQuery);
  },
  electroProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IElectroProtocol> => {
    let urlQuery = `/protocols/GetElectroProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
   },
  waterResistanceProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IWaterResistanceProtocol> => {
    let urlQuery = `/protocols/GetWaterResistanceProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
  },
  ridingNoisesProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IRidingNoisesProtocol> => {
    let urlQuery = `/protocols/GetRidingNoisesProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
  },
  acousticsProtocol: (carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IAcousticsProtocol> => {
    let urlQuery = `/protocols/GetAcousticsProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
  },
   eccProtocol: ( carId: number, protocolTypeId: number, carTestId: number | undefined, carAnalysisId: number | undefined,  carProtocolId?: number): Promise<IEecProtocol> => {
    let urlQuery = `/protocols/GetEECProtocol?carId=${carId}&protocolTypeId=${protocolTypeId}`;
    if (carTestId) urlQuery = urlQuery.concat(`&carTestId=${carTestId}`);
    if (carAnalysisId) urlQuery = urlQuery.concat(`&carAnalysisId=${carAnalysisId}`);
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
   },
  updateCarBodyProtocol: (carBodyProtocol: ICarBodyProtocol): Promise<ICarBodyProtocol> => requests.post("/protocols/UpdateCarBodyProtocol", carBodyProtocol),
  updateElectroProtocol: (electroProtocol: IElectroProtocol): Promise<IElectroProtocol> => requests.post("/protocols/UpdateElectroProtocol", electroProtocol),
  updateWaterResistanceProtocol: (waterResistanceProtocol: IWaterResistanceProtocol): Promise<IWaterResistanceProtocol> => requests.post("/protocols/UpdateWaterResistanceProtocol", waterResistanceProtocol),
  updateRidingNoisesProtocol: (ridingNoisesProtocol: IRidingNoisesProtocol): Promise<IRidingNoisesProtocol> => requests.post("/protocols/UpdateRidingNoisesProtocol", ridingNoisesProtocol),
  updateAcousticsProtocol: (acousticsProtocol: IAcousticsProtocol): Promise<IAcousticsProtocol> => requests.post("/protocols/UpdateAcousticsProtocol", acousticsProtocol),
  updateEECProtocol: (eecProtocol: IEecProtocol): Promise<IEecProtocol> => requests.post("/protocols/UpdateEECProtocol", eecProtocol),
  // TODO doplnit spravnou URL
  mainProtocol: ( carId: number, carProtocolId: number): Promise<IMainProtocol> => {
    let urlQuery = `/protocols/GetMainProtocol?carProtocolId=${carProtocolId}&carId=${carId}`;
    return requests.get(urlQuery);
  },
  getProtocolTypeInfo: (protocolTypeId: number): Promise<IProtocolTypeInfo> => {
      let urlQuery = `/protocols/GetProtocolTypeInfo?protocolTypeId=${protocolTypeId}`;
      return requests.get(urlQuery);
  },
  getProtocolAreas: (protocolTypeId: number): Promise<IProtocolAreasSetting> => {
    let urlQuery = `/protocols/GetProtocolAreas?protocolTypeId=${protocolTypeId}`;
    return requests.get(urlQuery);
  },
  getProtocolPartGroups: (protocolTypeId: number): Promise<IProtocolPartGroupsSetting> => {
    let urlQuery = `/protocols/GetProtocolPartGroups?protocolTypeId=${protocolTypeId}`;
    return requests.get(urlQuery);
  },
  getProtocolParts: (protocolTypeId: number): Promise<IProtocolPartsSetting> => {
    let urlQuery = `/protocols/GetProtocolParts?protocolTypeId=${protocolTypeId}`;
    return requests.get(urlQuery);
  },
  getProtocolRules: (protocolTypeId: number, carModelId?: number, carBodyType?: number): Promise<IProtocolRulesSetting> => {
    let urlQuery = `/protocols/GetProtocolRules?protocolTypeId=${protocolTypeId}`;
    if (carModelId) urlQuery = urlQuery.concat(`&carModelId=${carModelId}`);
    if (carBodyType) urlQuery = urlQuery.concat(`&carBodyTypeId=${carBodyType}`);
    return requests.get(urlQuery);
  },
  getProtocolEnums: (protocolTypeId: number): Promise<IProtocolEnums> => {
    let urlQuery = `/protocols/GetProtocolEnums?protocolTypeId=${protocolTypeId}`;
    return requests.get(urlQuery);
  },
  getProtocolCodeTableValues: (protocolTypeId: number, carTestId: number, protocolCodeTableId?: number): Promise<IProtocolCodeTableValues[]> => {
    let urlQuery = `/protocols/GetProtocolCodeTableValues?protocolTypeId=${protocolTypeId}&carTestId=${carTestId}`;
    if(protocolCodeTableId) urlQuery = urlQuery.concat(`&protocolCodeTableId=${protocolCodeTableId}`);
    return requests.get(urlQuery);
  },
  updateProtocolTypeInfo: (protocolTypeInfo: IProtocolTypeInfo): Promise<IProtocolTypeInfo> => requests.post("/protocols/UpdateProtocolTypeInfo", protocolTypeInfo),
  updateProtocolAreas: (settings: IProtocolAreasSetting): Promise<IProtocolAreasSetting> => requests.post("/protocols/UpdateProtocolAreas", settings),
  updateProtocolPartGroups: (settings: IProtocolPartGroupsSetting): Promise<IProtocolPartGroupsSetting> => requests.post("/protocols/UpdateProtocolPartGroups", settings),
  updateProtocolParts: (settings: IProtocolPartsSetting): Promise<IProtocolPartsSetting> => requests.post("/protocols/UpdateProtocolParts", settings),
  updateProtocolPart: (protocolPart: IPartSettingDTO): Promise<IPartSettingDTO> => requests.post("/protocols/UpdateProtocolPart", protocolPart),
  updateProtocolRule: (ruleSetting: IRuleSettingDTO): Promise<IRuleSettingDTO> => requests.post("/protocols/UpdateProtocolRule", ruleSetting),
  copyProtocolRules: (copyProtocolRules: IProtocolCopyRulesDTO): Promise<number> => requests.post("/protocols/copyProtocolRules", copyProtocolRules),
  conformityProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IConformityProtocol> => {
    let urlQuery = `/protocols/GetConformityProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
  },
  updateConformityProtocol: (conformityProtocol: IConformityProtocol): Promise<IWaterResistanceProtocol> => requests.post("/protocols/UpdateConformityProtocol", conformityProtocol),
  drivingCharacteristicsProtocol: ( carId: number, carTestId: number, protocolTypeId: number, carProtocolId?: number): Promise<IDrivingCharacteristicsProtocol> => {
    let urlQuery = `/protocols/GetEngineDrivingCharacteristicsProtocol?carId=${carId}&carTestId=${carTestId}&protocolTypeId=${protocolTypeId}`;
    if (carProtocolId) urlQuery = urlQuery.concat(`&carProtocolId=${carProtocolId}`);
    return requests.get(urlQuery);
  },
  updateDrivingCharacteristicsProtocol: (drivingCharacteristicsProtocol: IDrivingCharacteristicsProtocol): Promise<IDrivingCharacteristicsProtocol> => requests.post("/protocols/UpdateEngineDrivingCharacteristicsProtocol", drivingCharacteristicsProtocol),
}

const CarAnalysis = {
  createOrUpdate: (carAnalysisForm: ICarAnalysisForm): Promise<ICarAnalysis> =>
    requests.post("/carAnalysis", carAnalysisForm),
  get: (id: number): Promise<ICarAnalysis> =>
    requests.get(`/carAnalysis?carAnalysisId=${id}`),
}

const PlanningConditions = {
  get: (): Promise<IPlanningConditionsDTO> =>
    requests.get(`/planningConditions`),
  createOrUpdate: (planningConditions: IPlanningConditionsDTO): Promise<IPlanningConditionsDTO> =>
    requests.post("/planningConditions", planningConditions)
}

export default {
  Auths,
  BlockingEvents,
  Cars,
  CarTests,
  CopsIntegration,
  Enums,
  ExpertGroups,
  Settings,
  Scheduling,
  TestDefinitions,
  Users,
  UserDefinedFilters,
  Protocols,
  CarAnalysis,
  PlanningConditions,
};
